<template>
    <main class="main-container">
        <div class="wrapper">
            <div class="text-element text-center">
                <h1>Having bugs/questions or ideas? Contact me!</h1>
                <div class="flex justify-center items-center gap-2">
                    <a class="button"><i class="fab fa-github"></i><span>GitHub</span></a>
                    <a class="button"><i class="fab fa-discord"></i><span>Discord</span></a>
                </div>
            </div>
        </div>
    </main>
</template>